import Raicon from 'raicon';
import { formatCurrency, formatDate } from '@/layouts/application/utils/formats';
import handleMoneyField from '../../../layouts/application/utils/handleMoneyField';
import setupCpfLifeChart from '@/layouts/application/utils/charts/setupCpfLifeChart';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar from '../shared/handleUploadAvatar';
import handleLockForm from '../shared/handleLockForm';
import { getAge } from '@/layouts/application/utils/calculateAgeByInput';
import { handleHideSignSgd } from '../shared/handleHideSignSgd';
import { CurrencyHandler } from '../shared/currencyHandle';
import { DEFAULT_CURRENCY, CUTOFF_DATE } from '@/constants';

class ClientsIncomesController {
  constructor() {
    this.currencyHandler = new CurrencyHandler(
      '#currency-select',
      '#currency-select-business-salary',
      '#property-currency',
      '#property-currency-business-salary',
    );

    this.formSelector = '.main-salary-income-form';
    this.incomeIsForeignerLabel = '#income_is_foreigner_label';
    this.cpfContributionOnlyShow = '#cpf-contribution-only-show';
    this.incomeAmount = '#income_income_amount';
    this.incomeInterval = '#income_income_interval_type';
    this.employeeContribution = '#employee-contribution';
    this.employerContribution = '#employer-contribution';
    this.incomeEmployeeContribution = '#income_employee_contribution';
    this.incomeEmployerContribution = '#income_employer_contribution';
    this.chart = '#estimated_cpf_life_payout_modal_chart';
    this.chartInstance = null;
    this.incomeStartDate = '.income-start-date';
    this.incomeEndDate = '.income-end-date';
    this.incomeStartDateAge = '.income-start-date-age';
    this.incomeEndDateAge = '.income-end-date-age';
    this.clientId = $('#client-id-selector').data('value');
    this.hasPlatformWorker = '#income_has_platform_worker';
    this.hasPlatformWorkerClass = '.income_has_platform_worker';
    this.platformWorkerCheckbox = '#for-has-platform-worker';
    this.incomeHasBonus = '.income_has_bonus';
    this.hasBonusCheckbox = '#income_has_bonus';
    this.forHasBonus = '#for-has-bonus';
    this.platformWorkerTrueRadio = '#income_opt_in_platform_worker_true';
    this.platformWorkerFalseRadio = '#income_opt_in_platform_worker_false';
    this.forSelfEmployedSingaporean = '#for_self_employed_singaporean';
    this.employeeContributionText = '.employee-contribution-text';
    this.employerContributionText = '.employer-contribution-text';
    this.employeeContributionTooltip = '#employee-contribution-tooltip';
    this.employerContributionTooltip = '#employer-contribution-tooltip';
    this.isSelfEmployedCheckbox = '#income_is_self_employed';
    this.isSelfEmployedCheckboxLabel = '#income_is_self_employed_label';
    this.clientNationalityInfo = '#client-nationality-info';
  }

  beforeEach() {
    handleSideMenu();
    handleUploadAvatar();
  }

  new() {
    this.logicIncomeForm();
    handleMoneyField();
    handleHideSignSgd('#currency-select', '.property-currency');
    this.currencyHandler.currencyChangeHandler();
  }

  edit() {
    this.logicIncomeForm();
    handleMoneyField();
    handleLockForm(['#edit_income']);
    handleHideSignSgd('#currency-select', '.property-currency');
    this.currencyHandler.currencyChangeHandler();
    $('#currency-select').trigger('change');
    $('#currency-select-business-salary').trigger('change');
  }

  showCpfLifeChart() {
    setupCpfLifeChart(true);
  }

  newIncomesSelection() {}

  quizCareerIncome() {
    $('#check_send_mail').on('click', () => {
      if ($('#check_send_mail').is(':checked')) {
        $('#send-mail-create-quiz-to-contact').removeClass('disabled');
      } else {
        $('#send-mail-create-quiz-to-contact').addClass('disabled');
      }
    });
  }


  logicIncomeForm() {
    this.clientId = $('#client-id-selector').data('value');
    if ($('#main-salary-income').hasClass('show')) {
      if ($('#income_salary_income_type').val() == 'salary') {
        this.checkboxLogicIncomeSalary();
        this.toggleCheckbox();
        this.loadCpfContribution();
      }
    }

    $('.main-income-type-selector #overview-tab .nav-link').on('click', () => {
      setTimeout(() => {
        if ($('#main-salary-income').hasClass('show')) {
          if ($('#income_salary_income_type').val() == 'salary') {
            this.checkboxLogicIncomeSalary();
            this.toggleCheckbox();
            this.loadCpfContribution();
          }
        } else {
          $(this.formSelector).off('input');
        }
      }, 165);
    });

    this.calculageAgeOnDateChange();
    this.calculateIncomeStartDateAge();
    this.calculateIncomeEndDateAge();
    this.autoFillCheckboxWhenChooseClientToSyncData();
    this.handlePlatformWorkerRadios();
  }

  checkboxLogicIncomeSalary() {
    const isForeigner = $(this.clientNationalityInfo).data('is-foreigner');
    const isSelfEmployedSingaporean = $(this.isSelfEmployedCheckbox).is(':checked');
    const notOptInPlatformWorker = $(this.platformWorkerFalseRadio).is(':checked');
    const hasPlatformWorker = $(this.hasPlatformWorker).is(':checked');

    if (!isForeigner && !isSelfEmployedSingaporean) {
      $(this.cpfContributionOnlyShow).removeClass('d-none');
    } else {
      $(this.cpfContributionOnlyShow).addClass('d-none');
    }

    if (isForeigner) {
      $(this.isSelfEmployedCheckboxLabel).addClass('important-rule');
      $(this.isSelfEmployedCheckboxLabel).addClass('important-rule-before');
      $(this.forSelfEmployedSingaporean).hide();
      $(this.cpfContributionOnlyShow).hide();
    } else {
      if ((hasPlatformWorker && notOptInPlatformWorker) || isSelfEmployedSingaporean) {
        $(this.cpfContributionOnlyShow).hide();
        $(this.forSelfEmployedSingaporean).show();
      } else {
        $(this.cpfContributionOnlyShow).show();
        $(this.forSelfEmployedSingaporean).hide();
      }
    }
  }

  toggleCheckbox() {
    $('.main-salary-income-form').on('change', () => {
      this.checkboxLogicIncomeSalary();
    });
  }

  ajaxCallToCalculateCpfContribution() {
    const isSelfEmployedCheckbox = $(this.isSelfEmployedCheckbox).is(':checked');
    const usePlatformWorker = $(this.hasPlatformWorker).is(':checked') && !isSelfEmployedCheckbox;
    let currency = $('#currency-select').val() || DEFAULT_CURRENCY;

    $.ajax({
      url: Routes.prepare_cpf_contribution_clients_incomes_path({
        client_id: this.clientId,
        income: $(this.incomeAmount).val(),
        currency: currency,
        income_interval: $(this.incomeInterval).find(':selected').val(),
        income_is_self_employed: isSelfEmployedCheckbox,
        use_platform_worker: usePlatformWorker,
      }),
      type: 'GET',
      success: (data) => {
        $(this.incomeEmployeeContribution).val(data['employee_contribution']);
        $(this.incomeEmployerContribution).val(data['employer_contribution']);
        $(this.employeeContribution).text(formatCurrency(data['employee_contribution'], currency));
        $(this.employerContribution).text(formatCurrency(data['employer_contribution'], currency));
      },
    });
  }

  loadCpfContribution() {
    if ($(this.incomeAmount).val() == '0') {
      let currency = $('#currency-select').val() || DEFAULT_CURRENCY;

      $(this.employeeContribution).text(formatCurrency('0', currency));
      $(this.employerContribution).text(formatCurrency('0', currency));
    } else {
      this.ajaxCallToCalculateCpfContribution();
    }

    $(this.isSelfEmployedCheckbox).on('change', () => {
      this.ajaxCallToCalculateCpfContribution();
    });

    $(this.formSelector).on('input', `${this.incomeAmount}, ${this.incomeInterval}, ${this.currencySelector}`, () => {
      this.ajaxCallToCalculateCpfContribution();
    });

    $(this.formSelector).on('input', this.hasPlatformWorker, () => {
      this.ajaxCallToCalculateCpfContribution();
      this.triggerDisplayPlatformWorker();
      if ($(this.hasPlatformWorker).is(':checked') && $(this.platformWorkerFalseRadio).is(':checked')) {
        $(this.cpfContributionOnlyShow).hide();
        $(this.forSelfEmployedSingaporean).show();
      } else {
        $(this.cpfContributionOnlyShow).show();
        $(this.forSelfEmployedSingaporean).hide();
      }
    });

    $(this.formSelector).on('input', this.platformWorkerTrueRadio, () => {
      $(this.cpfContributionOnlyShow).show();
      $(this.forSelfEmployedSingaporean).hide();
    });

    $(this.formSelector).on('input', this.platformWorkerFalseRadio, () => {
      $(this.cpfContributionOnlyShow).hide();
      $(this.forSelfEmployedSingaporean).show();
    });

    $(this.formSelector).on('input', this.isSelfEmployedCheckbox, () => {
      this.triggerDisplayBySelfEmployedSingaporeanCheckbox();
    });

    this.triggerDisplayPlatformWorker();
    this.triggerDisplayBySelfEmployedSingaporeanCheckbox();
  }

  calculageAgeOnDateChange() {
    $(this.incomeStartDate).on('change', ({ target }) => {
      const startDate = new Date(target.value);
      if (startDate == 'Invalid Date') return;

      const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), startDate);
      $(this.incomeStartDate).val(formatDate(startDate));
      $(this.incomeStartDateAge).html(age);
      if ($(this.incomeEndDate).val() == '') {
        const retirementDate = new Date($(this.incomeStartDate).data('retirement-date'));
        const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), retirementDate);
        $(this.incomeEndDate).val(formatDate(retirementDate));
        $(this.incomeEndDateAge).html(age);
      }
    });

    $(this.incomeEndDate).on('change', ({ target }) => {
      const endDate = new Date(target.value);
      if (endDate == 'Invalid Date') return;

      const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), endDate);
      $(this.incomeEndDate).val(formatDate(endDate));
      $(this.incomeEndDateAge).html(age);
    });
  }

  calculateIncomeStartDateAge() {
    const startDate = $(this.incomeStartDate).val();
    if (startDate != '') {
      const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), new Date(startDate));
      $(this.incomeStartDateAge).html(age);
    }
  }

  calculateIncomeEndDateAge() {
    const endDate = $(this.incomeEndDate).val();
    if (endDate != '') {
      const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), new Date(endDate));
      $(this.incomeEndDateAge).html(age);
    }
  }

  handleHasBonusCheckbox() {
    const hasBonus = $(this.hasBonusCheckbox).is(':checked');
    $(this.forHasBonus).toggle(hasBonus);
  }

  triggerDisplayPlatformWorker() {
    if ($(this.hasPlatformWorker).is(':checked')) {
      $(this.platformWorkerCheckbox).show();
      $(this.incomeHasBonus).hide();
      $(this.hasBonusCheckbox).prop('checked', false);
      $(this.employeeContributionText).text("Platform Worker's Contribution");
      $(this.employerContributionText).text("Platform Operator's Contribution");
      $(this.employeeContributionTooltip).hide();
      $(this.employerContributionTooltip).hide();
    } else {
      $(this.platformWorkerCheckbox).hide();
      $(this.incomeHasBonus).show();
      $(this.employeeContributionText).text("Employee's Contribution");
      $(this.employerContributionText).text("Employer's Contribution");
      $(this.employeeContributionTooltip).show();
      $(this.employerContributionTooltip).show();
    }
    this.handleHasBonusCheckbox();
  }

  triggerDisplayBySelfEmployedSingaporeanCheckbox() {
    if ($(this.isSelfEmployedCheckbox).is(':checked')) {
      $(this.hasPlatformWorkerClass).hide();
      $(this.platformWorkerCheckbox).hide();
      $(this.incomeHasBonus).show();
    } else {
      $(this.hasPlatformWorkerClass).show();
      if ($(this.hasPlatformWorker).is(':checked')) {
        $(this.platformWorkerCheckbox).show();
        $(this.incomeHasBonus).hide();
        $(this.hasBonusCheckbox).prop('checked', false);
      } else {
        $(this.platformWorkerCheckbox).hide();
        $(this.incomeHasBonus).show();
      }
    }
  }

  autoFillCheckboxWhenChooseClientToSyncData() {
    $('.sync-to-client-radio').on('change', ({ target }) => {
      const incomeIsSelfEmployedSingaporean = $(this.isSelfEmployedCheckbox);

      this.clientId = target.value ? target.value : $('#client-id-selector').data('value');

      $.get(Routes.auto_fill_checkbox_by_selected_client_clients_incomes_path({ client_id: this.clientId })).done(
        (data) => {
          incomeIsSelfEmployedSingaporean.prop('checked', data.is_self_employed_singaporean);

          this.checkboxLogicIncomeSalary();
          this.ajaxCallToCalculateCpfContribution();
        },
      );
    });
  }

  handlePlatformWorkerRadios() {
    let wasCheckedBefore = $(this.hasPlatformWorker).is(':checked');
    const dateOfBirth = new Date($('#date-of-birth-data').data('dob-data'));
    $(this.hasPlatformWorker).on('change', () => {
      const isChecked = $(this.hasPlatformWorker).is(':checked');

      if (isChecked && !wasCheckedBefore) {
        $(this.platformWorkerTrueRadio).prop('disabled', false);
        if (dateOfBirth >= CUTOFF_DATE) {
          $(this.platformWorkerFalseRadio).prop('disabled', true);
          $(this.platformWorkerTrueRadio).prop('checked', true);
        } else {
          $(this.platformWorkerFalseRadio).prop('disabled', false);
        }
      }
    });

    if (wasCheckedBefore) {
      if (dateOfBirth >= CUTOFF_DATE) {
        $(this.platformWorkerFalseRadio).prop('disabled', true);
        $(this.platformWorkerTrueRadio).prop('checked', true);
      } else if ($(this.platformWorkerTrueRadio).is(':checked')) {
        $(this.platformWorkerFalseRadio).prop('disabled', true);
      }
    }
  }
}

Raicon.register('clients/incomes', ClientsIncomesController);
