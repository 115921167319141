import Raicon from 'raicon';
import initDoughnutChart from '@/layouts/application/utils/charts/initDoughnutChart';
import CashflowChart from './cashflowChart';
import CpfLife from './cpfLife';
import CpfBonus from './cpfBonus';
import { add10Referees } from '../add10Referees';
import { NET_WEALTH, LIABILITY } from '@/layouts/application/utils/charts/chartColors';
import { formatCurrency, formatDate } from '@/layouts/application/utils/formats';
import Chart from 'chart.js/auto';
import GiftingCalculatorClientForm from '../clients/giftingCalculatorForm';
import { getAge } from '@/layouts/application/utils/calculateAgeByInput';
import { bulkDeleteItem } from '../shared/bulkDeleteItem';
import { DEFAULT_CURRENCY, CUTOFF_DATE } from '@/constants';
import OverlayCanvas from '../../shared/overlayCanvas';

class ConsultantCashflowsController {
  constructor() {
    this.formSelector = '.new-income-modal-body';
    this.cashflowChartDetailContainer = '#cashflow-chart-details-container';
    this.cpfContributionOnlyShow = '#cpf-contribution-only-show';
    this.incomeAmount = '#income_income_amount';
    this.incomeInterval = '#income_income_interval_type';
    this.employeeContribution = '#employee-contribution';
    this.employerContribution = '#employer-contribution';
    this.incomeEmployeeContribution = '#income_employee_contribution';
    this.incomeEmployerContribution = '#income_employer_contribution';
    this.cashflowProjectionChart = null;
    this.incomeStartDate = '.income-start-date';
    this.incomeEndDate = '.income-end-date';
    this.incomeStartDateAge = '.income-start-date-age';
    this.incomeEndDateAge = '.income-end-date-age';
    this.clientId = $('body').data('client-id');
    this.isSelfEmployedCheckbox = '#income_is_self_employed';
    this.isSelfEmployedCheckboxLabel = '#income_is_self_employed_label';
    this.currencySelector = '#currency-select';
    this.currencySelectorBusinessSalary = '#currency-select-business-salary';
    this.propertyCurrency = '#property-currency';
    this.propertyCurrencyBusinessSalary = '#property-currency-business-salary';
    this.propertyCurrencyTextSelector = '.property-currency-text';
    this.hasPlatformWorker = '#income_has_platform_worker';
    this.hasPlatformWorkerClass = '.income_has_platform_worker';
    this.platformWorkerCheckbox = '#for-has-platform-worker';
    this.incomeHasBonus = '.income_has_bonus';
    this.hasBonusCheckbox = '#income_has_bonus';
    this.forHasBonus = '#for-has-bonus';
    this.platformWorkerTrueRadio = '#income_opt_in_platform_worker_true';
    this.platformWorkerFalseRadio = '#income_opt_in_platform_worker_false';
    this.forSelfEmployedSingaporean = '#for_self_employed_singaporean';
    this.employeeContributionText = '.employee-contribution-text';
    this.employerContributionText = '.employer-contribution-text';
    this.employeeContributionTooltip = '#employee-contribution-tooltip';
    this.employerContributionTooltip = '#employer-contribution-tooltip';
    this.clientNationalityInfo = '#client-nationality-info';
  }

  index() {
    this.drawCashflowChartMonth();
    this.drawCashflowChartYear();
    this.setupCashflowProjectionChart();
    add10Referees('#open-new-referee', '#multiple-referee-form');
    this.triggerContentCashflow();
    new CpfLife().setup();
    this.addTriggerOpenModalIncome();
    this.setupGiftingCalculatorForm();
    this.addTriggerOpenModalExpense();
    this.currencyChangeHandler();
    this.setupOverlayCanvas();
    bulkDeleteItem('#bulk-delete-income', '.income-checkbox');
    bulkDeleteItem('#bulk-delete-expense', '.expense-checkbox');
  }

  triggerContentCashflow() {
    $('.nav-link').on('click', (e) => {
      if ($(e.target).parent().hasClass('projection-nav')) {
        $('.cashflow-materials-content').addClass('d-none');
      } else {
        $('.cashflow-materials-content').removeClass('d-none');
      }
    });
  }

  setupGiftingCalculatorForm() {
    new GiftingCalculatorClientForm().setup();
  }

  setupOverlayCanvas() {
    new OverlayCanvas().setup();
  }

  currencyChangeHandler() {
    $('body').on('change', `${this.currencySelector}, ${this.currencySelectorBusinessSalary}`, (event) => {
      const selectedCurrency = $(event.target).val();
      const isBusinessSalary = $(event.target).is(this.currencySelectorBusinessSalary);
      const propertyCurrencySelector = isBusinessSalary ? this.propertyCurrencyBusinessSalary : this.propertyCurrency;
      if (selectedCurrency === 'SGD') {
        $(propertyCurrencySelector).removeClass('currency-sign-no-content').addClass('currency-sign');
      } else {
        $(propertyCurrencySelector).removeClass('currency-sign').addClass('currency-sign-no-content');
      }

      $(this.propertyCurrencyTextSelector).each(function () {
        let propertyValue = $(this).text();

        // Remove $ sign from digit part
        propertyValue = propertyValue.replace(/\$(\d+(,\d{3})*(\.\d+)?)/g, '$1');

        if (selectedCurrency !== 'SGD') {
          // If the currency is not SGD, just display the number
          $(this).text(propertyValue);
        } else {
          // If the currency is SGD, add the $ sign back
          $(this).text(propertyValue.replace(/(\d+(,\d{3})*(\.\d+)?)/g, '$$$1'));
        }
      });
    });
  }

  setupCashflowProjectionChart() {
    $('#cashflow-projection-chart-show-btn').on('click', () => {
      const $cashflowChartDetailsContainer = $(this.cashflowChartDetailContainer);
      let chartStatus = Chart.getChart($cashflowChartDetailsContainer);
      if (chartStatus != undefined) {
        return;
      }
      this.cashflowProjectionChart = new CashflowChart($cashflowChartDetailsContainer).setup();
    });
  }

  drawCashflowChartMonth() {
    const $chartElementMonth = $('#cashFlowChartMonth');
    const chartDataMonth = $chartElementMonth.parent().data('calculation');

    if ($chartElementMonth.length > 0) {
      initDoughnutChart($chartElementMonth, chartDataMonth, [NET_WEALTH, LIABILITY]);
    }
  }

  drawCashflowChartYear() {
    // Using to render chart if element is hidden
    const $chartElementYear = $('#cashFlowChartYear');

    $('a[href="#cashflow-year-show"]').on('shown.bs.tab', function () {
      if (!$chartElementYear.hasClass('rendered') && $chartElementYear.length > 0) {
        const nums_months_of_year = 12;
        const chartDataYear = $chartElementYear
          .parent()
          .data('calculation')
          .map((e) => e * nums_months_of_year);

        $chartElementYear.addClass('rendered');
        initDoughnutChart($chartElementYear, chartDataYear, [NET_WEALTH, LIABILITY]);
      }
    });
  }

  ajaxCallToCalculateCpfContribution() {
    const isSelfEmployedCheckbox = $(this.isSelfEmployedCheckbox).is(':checked');
    const usePlatformWorker = $(this.hasPlatformWorker).is(':checked') && !isSelfEmployedCheckbox;
    let currency = $(this.currencySelector).val() || DEFAULT_CURRENCY;

    $.ajax({
      url: Routes.prepare_cpf_contribution_consultant_client_income_path(this.clientId, {
        client_id: this.clientId,
        income: $(this.incomeAmount).val(),
        currency: currency,
        income_interval: $(this.incomeInterval).find(':selected').val(),
        income_is_self_employed: isSelfEmployedCheckbox,
        use_platform_worker: usePlatformWorker,
      }),
      type: 'GET',
      success: (data) => {
        $(this.incomeEmployeeContribution).val(data['employee_contribution'], currency);
        $(this.incomeEmployerContribution).val(data['employer_contribution'], currency);
        $(this.employeeContribution).text(formatCurrency(data['employee_contribution'], currency));
        $(this.employerContribution).text(formatCurrency(data['employer_contribution'], currency));
      },
    });
  }

  checkboxLogicIncomeSalary() {
    const isForeigner = $(this.clientNationalityInfo).data('is-foreigner');
    const isSelfEmployedSingaporean = $(this.isSelfEmployedCheckbox).is(':checked');
    const notOptInPlatformWorker = $(this.platformWorkerFalseRadio).is(':checked');
    const hasPlatformWorker = $(this.hasPlatformWorker).is(':checked');

    if (!isForeigner && !isSelfEmployedSingaporean) {
      $(this.cpfContributionOnlyShow).removeClass('d-none');
    } else {
      $(this.cpfContributionOnlyShow).addClass('d-none');
    }

    if (isForeigner) {
      $(this.isSelfEmployedCheckboxLabel).addClass('important-rule');
      $(this.isSelfEmployedCheckboxLabel).addClass('important-rule-before');
      $(this.forSelfEmployedSingaporean).hide();
      $(this.cpfContributionOnlyShow).hide();
    } else {
      if ((hasPlatformWorker && notOptInPlatformWorker) || isSelfEmployedSingaporean) {
        $(this.cpfContributionOnlyShow).hide();
        $(this.forSelfEmployedSingaporean).show();
      } else {
        $(this.cpfContributionOnlyShow).show();
        $(this.forSelfEmployedSingaporean).hide();
      }
    }
  }

  toggleCheckbox() {
    $('.main-salary-income-form').on('change', () => {
      this.checkboxLogicIncomeSalary();
      new CpfBonus().setup();
    });
  }

  addTriggerOpenModalIncome() {
    $('body').on('modal:afterSetContent', '#new-income-salary, #open-income-salary, #edit_income, #new_income', () => {
      new CpfBonus().setup();
      this.clientId = $('body').data('client-id');
      if ($('#main-salary-income').hasClass('show')) {
        if ($('#income_salary_income_type').val() == 'salary') {
          this.checkboxLogicIncomeSalary();
          this.toggleCheckbox();
          this.loadCpfContribution();
        }
      }

      $('.main-income-type-selector #overview-tab .nav-link').on('click', () => {
        setTimeout(() => {
          if ($('#main-salary-income').hasClass('show')) {
            if ($('#income_salary_income_type').val() == 'salary') {
              this.checkboxLogicIncomeSalary();
              this.toggleCheckbox();
              this.loadCpfContribution();
            }
          } else {
            $(this.formSelector).off('input');
          }
        }, 165);
      });

      $('#career-main-income').on('click', () => {
        $('#quiz_career_salary_income_form_modal').removeClass('d-none');
        $('#main-modal .income-modal').css('opacity', '0');
      });

      $('#hide-quiz-career-modal img').on('click', () => {
        $('#quiz_career_salary_income_form_modal').addClass('d-none');
        $('#main-modal .income-modal').css('opacity', '1');
      });

      $('#check_send_mail').on('click', () => {
        if ($('#check_send_mail').is(':checked')) {
          $('#send-mail-create-quiz-to-contact').removeClass('disabled');
        } else {
          $('#send-mail-create-quiz-to-contact').addClass('disabled');
        }
      });

      this.calculageAgeOnDateChange();
      this.calculateIncomeStartDateAge();
      this.calculateIncomeEndDateAge();
      this.autoFillCheckboxWhenChooseClientToSyncData();
      this.handlePlatformWorkerRadios();

      $(this.currencySelector).trigger('change');
      $(this.currencySelectorBusinessSalary).trigger('change');
    });

    $('body').on('modal:afterSetContent', '#open-property-asset', () => {
      $(this.currencySelector).trigger('change');
    });
  }

  loadCpfContribution() {
    if ($(this.incomeAmount).val() == '0') {
      let currency = $(this.currencySelector).val() || DEFAULT_CURRENCY;
      $(this.employeeContribution).text(formatCurrency('0', currency));
      $(this.employerContribution).text(formatCurrency('0', currency));
    } else {
      this.ajaxCallToCalculateCpfContribution();
    }

    $(this.isSelfEmployedCheckbox).on('change', () => {
      this.ajaxCallToCalculateCpfContribution();
      if ($(this.isSelfEmployedCheckbox).is(':checked')) {
        return;
      } else {
        $('#income_medisave_contribution').val(0);
      }
    });

    $(this.formSelector).on('input', `${this.incomeAmount}, ${this.incomeInterval}, ${this.currencySelector}`, () => {
      this.ajaxCallToCalculateCpfContribution();
    });

    $(this.formSelector).on('input', this.hasPlatformWorker, () => {
      this.ajaxCallToCalculateCpfContribution();
      this.triggerDisplayPlatformWorker();
      if ($(this.hasPlatformWorker).is(':checked') && $(this.platformWorkerFalseRadio).is(':checked')) {
        $(this.cpfContributionOnlyShow).hide();
        $(this.forSelfEmployedSingaporean).show();
      } else {
        $(this.cpfContributionOnlyShow).show();
        $(this.forSelfEmployedSingaporean).hide();
      }
    });

    $(this.formSelector).on('input', this.platformWorkerTrueRadio, () => {
      $(this.cpfContributionOnlyShow).show();
      $(this.forSelfEmployedSingaporean).hide();
    });

    $(this.formSelector).on('input', this.platformWorkerFalseRadio, () => {
      $(this.cpfContributionOnlyShow).hide();
      $(this.forSelfEmployedSingaporean).show();
    });

    $(this.formSelector).on('input', this.isSelfEmployedCheckbox, () => {
      this.triggerDisplayBySelfEmployedSingaporeanCheckbox();
    });

    this.triggerDisplayPlatformWorker();
    this.triggerDisplayBySelfEmployedSingaporeanCheckbox();
  }

  addTriggerOpenModalExpense() {
    $('body').on(
      'modal:afterSetContent',
      '#new-expense-living, #new-expense-rental, #new-expense-maintenance, #new-expense-happy-fund, #new-expense-subscription, #new-expense-gifting, #new-expense-other, #open-property-asset',
      () => {
        let initialValue = getAge($('#date-of-birth-data').data('dob-data'), new Date($('#expense_end_date').val()));
        $('#age-value').html(initialValue);

        $('#expense_end_date').on('change', () => {
          let ageValue = getAge($('#date-of-birth-data').data('dob-data'), new Date($('#expense_end_date').val()));
          $('#age-value').html(ageValue);
        });
      },
    );
  }

  calculageAgeOnDateChange() {
    $(this.incomeStartDate).on('change', ({ target }) => {
      const startDate = new Date(target.value);
      if (startDate == 'Invalid Date') return;

      const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), startDate);
      $(this.incomeStartDate).val(formatDate(startDate));
      $(this.incomeStartDateAge).html(age);
      if ($(this.incomeEndDate).val() == '') {
        const retirementDate = new Date($(this.incomeStartDate).data('retirement-date'));
        const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), retirementDate);
        $(this.incomeEndDate).val(formatDate(retirementDate));
        $(this.incomeEndDateAge).html(age);
      }
    });

    $(this.incomeEndDate).on('change', ({ target }) => {
      const endDate = new Date(target.value);
      if (endDate == 'Invalid Date') return;

      const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), endDate);
      $(this.incomeEndDate).val(formatDate(endDate));
      $(this.incomeEndDateAge).html(age);
    });
  }

  calculateIncomeStartDateAge() {
    const startDate = $(this.incomeStartDate).val();
    if (startDate != '') {
      const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), new Date(startDate));
      $(this.incomeStartDateAge).html(age);
    }
  }

  calculateIncomeEndDateAge() {
    const endDate = $(this.incomeEndDate).val();
    if (endDate != '') {
      const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), new Date(endDate));
      $(this.incomeEndDateAge).html(age);
    }
  }

  autoFillCheckboxWhenChooseClientToSyncData() {
    $('.sync-to-client-radio').on('change', ({ target }) => {
      const incomeIsSelfEmployedSingaporean = $(this.isSelfEmployedCheckbox);

      this.clientId = target.value ? target.value : $('body').data('client-id');

      $.get(
        Routes.auto_fill_checkbox_by_selected_client_consultant_client_incomes_path({ client_id: this.clientId }),
      ).done((data) => {
        incomeIsSelfEmployedSingaporean.prop('checked', data.is_self_employed_singaporean);

        this.checkboxLogicIncomeSalary();
        this.ajaxCallToCalculateCpfContribution();
      });
    });
  }

  handleHasBonusCheckbox() {
    const hasBonus = $(this.hasBonusCheckbox).is(':checked');
    $(this.forHasBonus).toggle(hasBonus);
  }

  triggerDisplayPlatformWorker() {
    if ($(this.hasPlatformWorker).is(':checked')) {
      $(this.platformWorkerCheckbox).show();
      $(this.incomeHasBonus).hide();
      $(this.hasBonusCheckbox).prop('checked', false);
      $(this.employeeContributionText).text("Platform Worker's Contribution");
      $(this.employerContributionText).text("Platform Operator's Contribution");
      $(this.employeeContributionTooltip).hide();
      $(this.employerContributionTooltip).hide();
    } else {
      $(this.platformWorkerCheckbox).hide();
      $(this.incomeHasBonus).show();
      $(this.employeeContributionText).text("Employee's Contribution");
      $(this.employerContributionText).text("Employer's Contribution");
      $(this.employeeContributionTooltip).show();
      $(this.employerContributionTooltip).show();
    }
    this.handleHasBonusCheckbox();
  }

  triggerDisplayBySelfEmployedSingaporeanCheckbox() {
    if ($(this.isSelfEmployedCheckbox).is(':checked')) {
      $(this.hasPlatformWorkerClass).hide();
      $(this.platformWorkerCheckbox).hide();
      $(this.incomeHasBonus).show();
    } else {
      $(this.hasPlatformWorkerClass).show();
      if ($(this.hasPlatformWorker).is(':checked')) {
        $(this.platformWorkerCheckbox).show();
        $(this.incomeHasBonus).hide();
        $(this.hasBonusCheckbox).prop('checked', false);
      } else {
        $(this.platformWorkerCheckbox).hide();
        $(this.incomeHasBonus).show();
      }
    }
  }

  handlePlatformWorkerRadios() {
    let wasCheckedBefore = $(this.hasPlatformWorker).is(':checked');
    const dateOfBirth = new Date($('#date-of-birth-data').data('dob-data'));

    $(this.hasPlatformWorker).on('change', () => {
      const isChecked = $(this.hasPlatformWorker).is(':checked');

      if (isChecked && !wasCheckedBefore) {
        $(this.platformWorkerTrueRadio).prop('disabled', false);
        if (dateOfBirth >= CUTOFF_DATE) {
          $(this.platformWorkerFalseRadio).prop('disabled', true);
          $(this.platformWorkerTrueRadio).prop('checked', true);
        } else {
          $(this.platformWorkerFalseRadio).prop('disabled', false);
        }
      }
    });

    if (wasCheckedBefore) {
      if (dateOfBirth >= CUTOFF_DATE) {
        $(this.platformWorkerFalseRadio).prop('disabled', true);
        $(this.platformWorkerTrueRadio).prop('checked', true);
      } else if ($(this.platformWorkerTrueRadio).is(':checked')) {
        $(this.platformWorkerFalseRadio).prop('disabled', true);
      }
    }
  }
}

Raicon.register('consultant/cashflows', ConsultantCashflowsController);
